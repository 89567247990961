import * as Sentry from '@sentry/react';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

export const getLocalStorageItem = <T>(
  key: string,
  defaultValue: T = {} as T,
): T => {
  const localStoreValue = localStorage.getItem(key);
  return localStoreValue
    ? parseJSON<T>(localStoreValue) ?? defaultValue
    : defaultValue;
};

export const addLocalStorageItem = (
  key: string,
  value: Record<string, unknown>,
): void => {
  localStorage.setItem(
    key,
    JSON.stringify({
      ...getLocalStorageItem(key),
      ...value,
    }),
  );
};

export const setLocalStorageItem = (
  key: string,
  value: Record<string, unknown>,
): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

type SetValue<T> = Dispatch<SetStateAction<T>>;

// Provides hook that persist the state with local storage
// https://usehooks-ts.com/react-hook/use-local-storage
export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, SetValue<T>] => {
  const readValue = useCallback((): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? parseJSON<T>(item) ?? initialValue : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      Sentry.captureException(error);
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value;
        if (newValue !== undefined) {
          localStorage.setItem(key, JSON.stringify(newValue));
        } else {
          localStorage.removeItem(key);
        }
        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
        Sentry.captureException(error);
      }
    },
    [key, storedValue],
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  return [storedValue, setValue];
};

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value && !['null', 'undefined'].includes(value)
      ? (JSON.parse(value ?? '') as T)
      : undefined;
  } catch (error) {
    console.error('parsing error on', { value });
    Sentry.captureException(error);
    return undefined;
  }
};
